var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{staticClass:"container px10 py20"},[_c('div',{staticClass:"row between-xs"},[_c('div',{staticClass:"col-xs-12 col-md-6"},[_c('h3',{staticClass:"h4 m0 pb10 uppercase weight-500"},[_vm._v("\n          "+_vm._s(_vm.$t('Reviews'))+"\n        ")]),_vm._v(" "),_c('reviews-list',{attrs:{"per-page":4,"items":_vm.reviews,"review-name":_vm.productTitle}})],1),_vm._v(" "),_c('div',{staticClass:"col-xs-12 col-md-5"},[_c('h3',{staticClass:"h4 m0 pb10 uppercase weight-500"},[_vm._v("\n          "+_vm._s(_vm.$t('Add review'))+"\n        ")]),_vm._v(" "),_c('form',{attrs:{"action":"#"},on:{"submit":function($event){$event.preventDefault();return _vm.outOfScope()}}},[_c('div',{staticClass:"mb20 name-input"},[_c('base-input',{attrs:{"type":"text","placeholder":_vm.$t('First name') + ' *',"validations":[
                {
                  condition: _vm.$v.formData.name.$error && !_vm.$v.formData.name.required,
                  text: _vm.$t('Field is required')
                },
                {
                  condition: !_vm.$v.formData.name.minLength,
                  text: _vm.$t('Name must have at least 2 letters.')
                }
              ]},on:{"blur":function($event){return _vm.$v.formData.name.$touch()}},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})],1),_vm._v(" "),_c('div',{staticClass:"mb10"},[_c('base-input',{attrs:{"type":"email","placeholder":_vm.$t('Email address') + ' *',"validations":[
                {
                  condition: _vm.$v.formData.email.$error && !_vm.$v.formData.email.required,
                  text: _vm.$t('Field is required')
                },
                {
                  condition: !_vm.$v.formData.email.email && _vm.$v.formData.email.$error,
                  text: _vm.$t('Please provide valid e-mail address.')
                }
              ]},on:{"blur":function($event){return _vm.$v.formData.email.$touch()}},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}})],1),_vm._v(" "),_c('div',{staticClass:"flex mt20 mb10"},[_c('span',{staticClass:"stars-input pointer flex"},[_c('i',{staticClass:"cl-black pointer h3",class:{ 'cl-yellow' : _vm.stars >= 1},on:{"click":function($event){return _vm.changeStars(1)}}},[_vm._v("★")]),_vm._v(" "),_c('i',{staticClass:"cl-black pointer h3",class:{ 'cl-yellow' : _vm.stars >= 2},on:{"click":function($event){return _vm.changeStars(2)}}},[_vm._v("★")]),_vm._v(" "),_c('i',{staticClass:"cl-black pointer h3",class:{ 'cl-yellow' : _vm.stars >= 3},on:{"click":function($event){return _vm.changeStars(3)}}},[_vm._v("★")]),_vm._v(" "),_c('i',{staticClass:"cl-black pointer h3",class:{ 'cl-yellow' : _vm.stars >= 4},on:{"click":function($event){return _vm.changeStars(4)}}},[_vm._v("★")]),_vm._v(" "),_c('i',{staticClass:"cl-black pointer h3",class:{ 'cl-yellow' : _vm.stars >= 5},on:{"click":function($event){return _vm.changeStars(5)}}},[_vm._v("★")])])]),_vm._v(" "),_c('div',{staticClass:"mb25"},[_c('base-textarea',{attrs:{"type":"text","placeholder":_vm.$t('Review') + ' *',"validations":[
                {
                  condition: _vm.$v.formData.review.$error && !_vm.$v.formData.review.required,
                  text: _vm.$t('Field is required')
                }
              ]},on:{"blur":function($event){return _vm.$v.formData.review.$touch()}},model:{value:(_vm.formData.review),callback:function ($$v) {_vm.$set(_vm.formData, "review", $$v)},expression:"formData.review"}})],1),_vm._v(" "),_c('div',{staticClass:"row m0 middle-xs center-xs start-sm buttons"},[_c('button-full',{class:{ 'w-auto': !_vm.currentUser },nativeOn:{"click":function($event){return _vm.validate()}}},[_vm._v("\n              "+_vm._s(_vm.$t('Add review'))+"\n            ")]),_vm._v(" "),_c('no-ssr',[(!_vm.currentUser)?_c('span',{staticClass:"fs-medium ml20 lh30 py5 block"},[_vm._v("\n                "+_vm._s(_vm.$t('or'))+" "),_c('a',{staticClass:"cl-primary",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.login()}}},[_vm._v(_vm._s(_vm.$t('login')))]),_vm._v(" "+_vm._s(_vm.$t('to account'))+"\n              ")]):_vm._e()])],1)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }