<template>
  <div class="px10 py20">
    <h3 class="h4 weight-500 m0 pb10">
      {{ $t('DANE TECHNICZNE') }}
    </h3>
    <p v-show="getMinMaxValueForwidth" class="flex between-xs m0 py15 cl-matterhorn custom-border-bt middle-xs">
      <span class="flex middle-xs">{{ $t('Szerokość (mm)') }} </span>
      <span>{{ getMinMaxValueForwidth }}</span>
    </p>
    <p v-show="getMinMaxValueForheight" class="flex between-xs m0 py15 cl-matterhorn custom-border-bt middle-xs">
      <span class="flex middle-xs">{{ $t('Wysokość (mm)') }} </span>
      <span>{{ getMinMaxValueForheight }}</span>
    </p>
    <p v-if="getCurrentProduct.depth" class="flex between-xs m0 py15 cl-matterhorn custom-border-bt middle-xs">
      <span class="flex middle-xs">{{ $t('Głębokość (mm)') }} </span>
      <span>{{ getLabelforValue('depth', getCurrentProduct.depth) }}</span>
    </p>
    <p v-if="getCurrentProduct.connection_width" class="flex between-xs m0 py15 cl-matterhorn custom-border-bt middle-xs word-no-break">
      <span class="flex middle-xs">{{ $t('Szerokość połączenia (mm)') }} </span>
      <span>{{ getLabelArraysforValues('connection_width', getCurrentProduct.connection_width) }}</span>
    </p>
    <p v-if="getCurrentProduct.connection_code" class="flex between-xs m0 py15 cl-matterhorn custom-border-bt middle-xs">
      <span class="flex middle-xs">{{ $t('Kod podłączenia') }} </span>
      <span>{{ getLabelArraysforValues('connection_code', getCurrentProduct.connection_code) }}</span>
    </p>
    <p v-if="getCurrentProduct.connection_pos_hor" class="flex between-xs m0 py15 cl-matterhorn custom-border-bt middle-xs">
      <span class="flex middle-xs">{{ $t('Przyłączenie (pozycja pozioma)') }} </span>
      <span>{{ getLabelArraysforValues('connection_pos_hor', getCurrentProduct.connection_pos_hor) }}</span>
    </p>
    <p v-if="getCurrentProduct.connection_pos_vert" class="flex between-xs m0 py15 cl-matterhorn custom-border-bt middle-xs">
      <span class="flex middle-xs">{{ $t('Przyłączenie (pozycja pionowa)') }} </span>
      <span>{{ getLabelArraysforValues('connection_pos_vert', getCurrentProduct.connection_pos_vert) }}</span>
    </p>
    <p v-if="getCurrentProduct.working_pressure" class="flex between-xs m0 py15 cl-matterhorn custom-border-bt middle-xs">
      <span class="flex middle-xs">{{ $t('working_pressure') }} </span>
      <span>{{ getLabelforValue('working_pressure', getCurrentProduct.working_pressure) }}</span>
    </p>
    <p v-if="getCurrentProduct.mount" class="flex between-xs m0 py15 cl-matterhorn custom-border-bt middle-xs">
      <span class="flex middle-xs">{{ $t('mount_filter') }} </span>
      <span>{{ getLabelforValue('mount', getCurrentProduct.mount) }}</span>
    </p>
    <p v-if="getCurrentProduct.material" class="flex between-xs m0 py15 cl-matterhorn custom-border-bt middle-xs">
      <span class="flex middle-xs">{{ $t('material') }} </span>
      <span>{{ getLabelforValue('material', getCurrentProduct.material) }}</span>
    </p>
    <p v-if="getCurrentProduct.heater_elements" class="flex between-xs m0 py15 cl-matterhorn custom-border-bt middle-xs">
      <span class="flex middle-xs">{{ $t('heater_elements') }} </span>
      <span>{{ getCurrentProduct.heater_elements }}</span>
    </p>
    <p v-if="getCurrentProduct.pipes" class="flex between-xs m0 py15 cl-matterhorn custom-border-bt middle-xs">
      <span class="flex middle-xs">{{ $t('pipes') }} </span>
      <span>{{ getCurrentProduct.pipes }}</span>
    </p>
    <p v-if="getCurrentProduct.mount_position" class="flex between-xs m0 py15 cl-matterhorn custom-border-bt middle-xs">
      <span class="flex middle-xs">{{ $t('mount_position') }} </span>
      <span>{{ getCurrentProduct.mount_position }}</span>
    </p>
    <p v-if="getCurrentProduct.model" class="flex between-xs m0 py15 cl-matterhorn custom-border-bt middle-xs">
      <span class="flex middle-xs">{{ $t('Seria produktu') }} </span>
      <span>{{ getCurrentProduct.model }}</span>
    </p>
    <p v-if="getCurrentProduct.heater_type" class="flex between-xs m0 py15 cl-matterhorn custom-border-bt middle-xs">
      <span class="flex middle-xs">{{ $t('heater_type') }} </span>
      <template v-if="getLabelforValue('heater_type', getCurrentProduct.heater_type) === 'W'">
        <span>{{ $t('Wodny') }}</span>
      </template>
      <template v-if="getLabelforValue('heater_type', getCurrentProduct.heater_type) === 'WM'">
        <span>{{ $t('WM') }}</span>
      </template>
      <template v-if="getLabelforValue('heater_type', getCurrentProduct.heater_type) === 'E'">
        <span>{{ $t('Elektryczny') }}</span>
      </template>
    </p>
    <p v-if="getCurrentProduct.country_of_manufacture" class="flex between-xs m0 py15 cl-matterhorn custom-border-bt middle-xs">
      <span class="flex middle-xs">{{ $t('Kraj produkcji') }} </span>
      <span>{{ getLabelforValue('country_of_manufacture', getCurrentProduct.country_of_manufacture) }}</span>
    </p>
    <p v-if="getCurrentProduct.manufacturer" class="flex between-xs m0 py15 cl-matterhorn custom-border-bt middle-xs">
      <span class="flex middle-xs">{{ $t('Producent') }} </span>
      <span>{{ getLabelforValue('manufacturer', getCurrentProduct.manufacturer) }}</span>
    </p>
    <p v-if="getCurrentProduct.parent_free_valves" class="flex between-xs m0 py15 cl-matterhorn custom-border-bt middle-xs">
      <span class="flex middle-xs">{{ $t('free_valves') }} </span>
      <template v-if="getCurrentProduct.parent_free_valves === 1">
        <span>{{ $t('Yes') }}</span>
      </template>
    </p>
    <p v-if="getCurrentProduct.delivery_time" class="flex between-xs m0 py15 cl-matterhorn custom-border-bt middle-xs">
      <span class="flex middle-xs">{{ $t('Czas realizacji') }} </span>
      <span>{{ getCurrentProduct.delivery_time }} dni</span>
    </p>
    <p v-if="getCurrentProduct.equipment_set" class="flex between-xs m0 py15 cl-matterhorn custom-border-bt middle-xs">
      <span class="flex middle-xs pr15">{{ $t('Zestaw sprzętu') }} </span>
      <span>{{ getCurrentProduct.equipment_set }}</span>
    </p>
    <div v-if="getCurrentProduct.description" id="styles-from-magento" v-html="getCurrentProduct.description" />
    <div v-else-if="getCurrentProduct.short_description" id="styles-from-magento" v-html="getCurrentProduct.short_description" />
  </div>
</template>

<script>
import getLabelMixin from 'theme/mixins/getLabelMixin.ts'
import minMaxValueMixin from 'theme/mixins/product/minMaxValueMixin.ts'

export default {
  props: {
    getCurrentProduct: {
      type: Object,
      required: true
    }
  },
  mixins: [getLabelMixin, minMaxValueMixin]
}
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';

$color-orange: color(primary-orange);

.word-no-break {
  word-break: break-word;
}
.use-list {
  list-style: none;
  li {
    position: relative;
    &:before {
      content: "\25A0";
      color: $color-orange;
      position: absolute;
      left: -20px;
    }
  }
}
.custom-border-bt {
  border-bottom: 2px solid #d9d9d9;
  @media (max-width: 767px) {
    &:last-child {
      border: none;
    }
  }
}
</style>
