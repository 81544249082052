<template>
  <div>
    <section class="container px10 py20">
      <div class="row between-xs">
        <div class="col-xs-12 col-md-6">
          <h3 class="h4 m0 pb10 uppercase weight-500">
            {{ $t('Reviews') }}
          </h3>
          <reviews-list
            :per-page="4"
            :items="reviews"
            :review-name="productTitle"
          />
        </div>
        <div class="col-xs-12 col-md-5">
          <h3 class="h4 m0 pb10 uppercase weight-500">
            {{ $t('Add review') }}
          </h3>
          <form action="#" @submit.prevent="outOfScope()">
            <div class="mb20 name-input">
              <base-input
                type="text"
                :placeholder="$t('First name') + ' *'"
                v-model="formData.name"
                @blur="$v.formData.name.$touch()"
                :validations="[
                  {
                    condition: $v.formData.name.$error && !$v.formData.name.required,
                    text: $t('Field is required')
                  },
                  {
                    condition: !$v.formData.name.minLength,
                    text: $t('Name must have at least 2 letters.')
                  }
                ]"
              />
            </div>
            <div class="mb10">
              <base-input
                type="email"
                :placeholder="$t('Email address') + ' *'"
                v-model="formData.email"
                @blur="$v.formData.email.$touch()"
                :validations="[
                  {
                    condition: $v.formData.email.$error && !$v.formData.email.required,
                    text: $t('Field is required')
                  },
                  {
                    condition: !$v.formData.email.email && $v.formData.email.$error,
                    text: $t('Please provide valid e-mail address.')
                  }
                ]"
              />
            </div>
            <!-- <div class="mb10">
              <base-input
                type="text"
                :placeholder="$t('Summary') + ' *'"
                v-model="formData.summary"
                @blur="$v.formData.summary.$touch()"
                :validations="[
                  {
                    condition: $v.formData.summary.$error && !$v.formData.summary.required,
                    text: $t('Field is required')
                  }
                ]"
              />
            </div> -->
            <div class="flex mt20 mb10">
              <span class="stars-input pointer flex">
                <i class="cl-black pointer h3" :class="{ 'cl-yellow' : stars >= 1}" @click="changeStars(1)">&#9733;</i>
                <i class="cl-black pointer h3" :class="{ 'cl-yellow' : stars >= 2}" @click="changeStars(2)">&#9733;</i>
                <i class="cl-black pointer h3" :class="{ 'cl-yellow' : stars >= 3}" @click="changeStars(3)">&#9733;</i>
                <i class="cl-black pointer h3" :class="{ 'cl-yellow' : stars >= 4}" @click="changeStars(4)">&#9733;</i>
                <i class="cl-black pointer h3" :class="{ 'cl-yellow' : stars >= 5}" @click="changeStars(5)">&#9733;</i>
              </span>
            </div>
            <div class="mb25">
              <base-textarea
                type="text"
                :placeholder="$t('Review') + ' *'"
                v-model="formData.review"
                @blur="$v.formData.review.$touch()"
                :validations="[
                  {
                    condition: $v.formData.review.$error && !$v.formData.review.required,
                    text: $t('Field is required')
                  }
                ]"
              />
            </div>
            <div class="row m0 middle-xs center-xs start-sm buttons">
              <button-full
                @click.native="validate()"
                :class="{ 'w-auto': !currentUser }"
              >
                {{ $t('Add review') }}
              </button-full>
              <no-ssr>
                <span
                  class="fs-medium ml20 lh30 py5 block"
                  v-if="!currentUser"
                >
                  {{ $t('or') }} <a href="#" class="cl-primary" @click.prevent="login()">{{ $t('login') }}</a> {{ $t('to account') }}
                </span>
              </no-ssr>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { required, email, minLength } from 'vuelidate/lib/validators'

import BaseInput from 'theme/components/core/blocks/Form/BaseInput'
import BaseTextarea from 'theme/components/core/blocks/Form/BaseTextarea'
import ButtonFull from 'theme/components/theme/ButtonFull'
import ReviewsList from 'theme/components/theme/blocks/Reviews/ReviewsList'
import { Reviews } from '@vue-storefront/core/modules/review/components/Reviews'
import { AddReview } from '@vue-storefront/core/modules/review/components/AddReview'
import NoSSR from 'vue-no-ssr'
import i18n from '@vue-storefront/i18n'

export default {
  name: 'Reviews',
  data () {
    return {
      formData: {
        name: '',
        email: '',
        summary: 'summary',
        review: ''
      },
      stars: 5
    }
  },
  props: {
    productId: {
      type: [String, Number],
      required: true
    },
    productTitle: {
      type: String,
      default: ''
    }
  },
  computed: {
    currentUser () {
      return this.$store.state.user.current
    }
  },
  methods: {
    changeStars (value) {
      this.stars = value
    },
    validate () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.submit()
      }
    },
    refreshList () {
      this.$store.dispatch('review/list', { productId: this.productId })
    },
    async submit () {
      const isReviewCreated = await this.$store.dispatch('review/add', {
        'product_id': this.productId,
        'title': this.formData.summary,
        'detail': this.formData.review,
        'nickname': this.formData.name,
        'ratings': [
          {
            'rating_name': 'Value',
            'value': this.stars
          }
        ],
        'review_entity': 'product',
        'customer_id': this.currentUser ? this.currentUser.id : null
      })

      if (isReviewCreated) {
        this.$store.dispatch('notification/spawnNotification', {
          type: 'success',
          message: i18n.t('You submitted your review for moderation.'),
          action1: { label: i18n.t('OK') }
        })

        return
      }

      this.$store.dispatch('notification/spawnNotification', {
        type: 'error',
        message: i18n.t('Something went wrong. Try again in a few seconds.'),
        action1: { label: i18n.t('OK') }
      })
    },
    clearReviewForm () {
      this.formData.name = ''
      this.formData.email = ''
      this.formData.summary = 'summary'
      this.formData.review = ''
      this.$v.$reset()
    },
    login () {
      this.$bus.$emit('modal-show', 'modal-signup')
    },
    fillInUserData () {
      if (this.currentUser) {
        this.formData.name = this.currentUser.firstname
        this.formData.email = this.currentUser.email
      }
    }
  },
  mounted () {
    this.$bus.$on('product-after-load', this.refreshList)
    this.$bus.$on('clear-add-review-form', this.clearReviewForm)
    this.$bus.$on('user-after-loggedin', this.fillInUserData)
  },
  destroyed () {
    this.$bus.$off('product-after-load', this.refreshList)
    this.$bus.$off('clear-add-review-form', this.clearReviewForm)
    this.$bus.$off('user-after-loggedin', this.fillInUserData)
  },
  beforeMount () {
    this.refreshList()
    this.fillInUserData()
  },
  mixins: [ Reviews ],
  validations: {
    formData: {
      name: {
        minLength: minLength(2),
        required
      },
      email: {
        required,
        email
      },
      summary: {
        // required
      },
      review: {
        required
      }
    }
  },
  components: {
    ButtonFull,
    BaseInput,
    BaseTextarea,
    ReviewsList,
    'no-ssr': NoSSR
  }
}
</script>

<style lang="scss" scoped>
.name-input {
  padding-top: 40px;
  @media (max-width: 767px) {
    padding-top: 20px;
  }
}
.pt50-sm {
  padding-top: 50px;
}
@media (max-width: 767px) {
 .pt50-sm {
   padding-top: 25px;
 }
}
.stars-input:hover {
  i {
    color: #E8C900;
  }
}
.stars-input > i:hover ~ i {
  color: black;
}
</style>
