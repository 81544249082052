<template>
  <div class="mobile-datails">
    <section name="ProductTabs" id="tabs" class="container cl-black hidden-xs">
      <div class="row m0">
        <div class="tabs col-md-8">
          <div class="row m0 bg-cl-white shadow-hover">
            <div class="col-md-12">
              <ul class="tablist">
                <li
                  v-show="!isAccess && isRelatedProducts"
                  @mouseover="updateSelected(1)"
                  :class="{ 'current': selectedTab === 1 }"
                >
                  <h3 class="h5 m0 weight-500">
                    {{ $t('Zalecane akcesoria') }}
                  </h3>
                </li>
                <li
                  @mouseover="updateSelected(2)"
                  :class="{ 'current': selectedTab === 2 }"
                >
                  <h3 class="h5 m0 weight-500">
                    {{ $t('Opis produktu') }}
                  </h3>
                </li>
                <li
                  v-show="categoryDesc !== ''"
                  @mouseover="updateSelected(3)"
                  :class="{ 'current': selectedTab === 3 }"
                >
                  <h3 class="h5 m0 weight-500">
                    {{ $t('Producent') }}
                  </h3>
                </li>
                <li
                  @mouseover="updateSelected(4)"
                  :class="{ 'current': selectedTab === 4 }"
                >
                  <h3 class="h5 m0 weight-500">
                    {{ $t('Opinie') }}
                  </h3>
                </li>
                <li
                  v-show="!isAccess && isConnCodes && imageResultResponse"
                  @mouseover="updateSelected(5)"
                  :class="{ 'current': selectedTab === 5 }"
                >
                  <h3 class="h5 m0 weight-500">
                    {{ $t('Kody podłączeń') }}
                  </h3>
                </li>
                <li
                  @mouseover="updateSelected(6)"
                  :class="{ 'current': selectedTab === 6 }"
                >
                  <h3 class="h5 m0 weight-500">
                    {{ $t('Pliki do pobrania') }}
                  </h3>
                </li>
              </ul>
              <div v-show="selectedTab === 1" class="modal-tabContent py20 px10">
                <div class="h4 cl-black">
                  <div class="row between-md m0">
                    <div class="col-xs-2 col-sm-12 p0">
                      <p class="m0 h5 cl-alternative">
                        {{ $t('Do grzejnika należy dokupić armaturę przyłączeniową, poniżej dedykowane zawory:') }}
                      </p>
                      <related-product-tab :type="'crosssell'" />
                    </div>
                  </div>
                </div>
              </div>
              <product-desc v-show="selectedTab === 2" :get-current-product="prod" />
              <div v-show="selectedTab === 3" id="styles-from-magento" v-html="categoryDesc" class="tabContent tabDescription px10 py5" />
              <div v-show="selectedTab === 4" class="tabContent">
                <lazy-hydrate when-idle>
                  <reviews :product-title="getOriginalProduct.name" :product-id="getOriginalProduct.parent_id" />
                </lazy-hydrate>
              </div>
              <div v-show="selectedTab === 5" class="tabContent">
                <connection-codes :prod="prod" class="px10 py20" />
              </div>
              <div v-show="selectedTab === 6" class="modal-tabContent">
                <for-download v-if="selectedTab === 6" :prod="prod" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4" />
      </div>
    </section>
    <section name="ProductAccord" class="cl-black mt15 visible-xs">
      <div id="acc-scroll-to" class="button" v-show="!isAccess && isRelatedProducts">
        <p class="accord-button uppercase" @click="toogleAccessories()">
          <template v-if="showAccessories">
            {{ $t('Zalecane akcesoria') }} <i class="arrow top" />
          </template>
          <template v-else>
            {{ $t('Zalecane akcesoria') }} <i class="arrow bottom" />
          </template>
        </p>
      </div>
      <div v-show="showAccessories" class="mt20">
        <div class="row between-xs m0">
          <div class="col-xs-12 p0">
            <p v-if="product.related['crosssell'] && product.related['crosssell'].length > 0" class="m0 h5 cl-black pl10 pb10">
              {{ $t('Do grzejnika należy dokupić armaturę przyłączeniową, poniżej dedykowane zawory:') }}
            </p>
            <related-product-tab type="crosssell" />
          </div>
        </div>
      </div>
      <div class="button">
        <p class="accord-button uppercase" @click="toogleDescription()">
          <template v-if="showDescription">
            {{ $t('Opis produktu') }} <i class="arrow top" />
          </template>
          <template v-else>
            {{ $t('Opis produktu ') }} <i class="arrow bottom" />
          </template>
        </p>
      </div>
      <product-desc class="px10" v-show="showDescription" :get-current-product="prod" />
      <div v-show="categoryDesc !== ''" class="button">
        <p class="accord-button uppercase" @click="toogleProducent()">
          <template v-if="showProducent">
            {{ $t('Producent') }} <i class="arrow top" />
          </template>
          <template v-else>
            {{ $t('Producent') }} <i class="arrow bottom" />
          </template>
        </p>
      </div>
      <div v-show="showProducent" id="styles-from-magento" v-html="categoryDesc" class="tabContent tabDescription px10 custom-border" />
      <div class="button">
        <p class="accord-button uppercase" @click="toogleRewiew()">
          <template v-if="showRewiew">
            {{ $t('Reviews') }} <i class="arrow top" />
          </template>
          <template v-else>
            {{ $t('Reviews') }} <i class="arrow bottom" />
          </template>
        </p>
      </div>
      <div v-show="showRewiew">
        <lazy-hydrate when-idle>
          <reviews :product-title="getOriginalProduct.name" :product-id="getOriginalProduct.parent_id" />
        </lazy-hydrate>
      </div>
      <div class="button" v-show="!isAccess && isConnCodes && imageResultResponse">
        <p class="accord-button uppercase" @click="toogleConnectionCode()">
          <template v-if="showConnectionCode">
            {{ $t('Kody podłączeń') }} <i class="arrow top" />
          </template>
          <template v-else>
            {{ $t('Kody podłączeń') }} <i class="arrow bottom" />
          </template>
        </p>
      </div>
      <div v-show="showConnectionCode">
        <div class="row between-xs m0">
          <div class="col-xs-12">
            <h3 class="h4 weight-500 uppercase my15">
              {{ $t('Kody podłączeń hydraulicznych') }}
            </h3>
            <p>{{ $t('Możliwe dla danego modelu kody podłączeń znajdują się na stronie produktu') }}</p>
            <connection-codes :prod="prod" />
          </div>
        </div>
      </div>
      <div class="button">
        <p class="accord-button uppercase" @click="toogleForDownloads()">
          <template v-if="showForDownloads">
            {{ $t('Pliki do pobrania') }} <i class="arrow top" />
          </template>
          <template v-else>
            {{ $t('Pliki do pobrania') }} <i class="arrow bottom" />
          </template>
        </p>
      </div>
      <div v-show="showForDownloads">
        <for-download v-if="showForDownloads" :prod="prod" />
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LazyHydrate from 'vue-lazy-hydration'
import { onlineHelper, isServer } from '@vue-storefront/core/helpers'
import Reviews from 'theme/components/core/blocks/Reviews/Reviews.vue'
import RelatedProductTab from './RelatedProductTab.vue'
// import LogoButton from 'theme/components/core/LogoButton.vue'
import ConnectionCodes from './ConnectionCodes.vue'
import ProductDesc from './ProductDesc.vue'
// import ForDownload from './ForDownload.vue'
import { isInAccessorizes } from '@vue-storefront/core/helpers/index'
import { quickSearchByQuery } from '@vue-storefront/core/lib/search';
import SearchQuery from '@vue-storefront/core/lib/search/searchQuery';
import config from 'config';

const ForDownload = () => import(/* webpackChunkName: "for-download" */ './ForDownload')

export default {
  components: {
    Reviews,
    RelatedProductTab,
    // LogoButton,
    ProductDesc,
    ForDownload,
    LazyHydrate,
    ConnectionCodes
  },

  beforeMount () {
    this.productManufacturer(this.prod.category)
  },
  props: {
    prod: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      tabs: [
        { id: 1, title: 'Zalecane akcesoria', content: '' },
        { id: 2, title: 'Opis produktu', content: '' },
        { id: 3, title: 'Producent', content: '' },
        { id: 4, title: 'Opinie', content: '' },
        { id: 5, title: 'Kody podłączeń', content: '' },
        { id: 6, title: 'Pliki do pobrania', content: '' }
      ],
      connection_codes: [
        { image: '/assets/connection-codes/radox_kod_12.svg', alt: 'radox_kod_12 connection code' },
        { image: '/assets/connection-codes/radox_kod_15.svg', alt: 'radox_kod_15 connection code' },
        { image: '/assets/connection-codes/radox_kod_25.svg', alt: 'radox_kod_25 connection code' },
        { image: '/assets/connection-codes/radox_kod_26.svg', alt: 'radox_kod_26 connection code' },
        { image: '/assets/connection-codes/radox_kod_34.svg', alt: 'radox_kod_34 connection code' },
        { image: '/assets/connection-codes/radox_kod_37.svg', alt: 'radox_kod_37 connection code' },
        { image: '/assets/connection-codes/radox_kod_65.svg', alt: 'radox_kod_65 connection code' },
        { image: '/assets/connection-codes/radox_kod_84.svg', alt: 'radox_kod_84 connection code' },
        { image: '/assets/connection-codes/radox_kod_D45.svg', alt: 'radox_kod_D45 connection code' },
        { image: '/assets/connection-codes/radox_kod_D50.svg', alt: 'radox_kod_D50 connection code' },
        { image: '/assets/connection-codes/radox_kod_D50L.svg', alt: 'radox_kod_D50L connection code' },
        { image: '/assets/connection-codes/radox_kod_D50P.svg', alt: 'radox_kod_D50P onnection code' },
        { image: '/assets/connection-codes/radox_kod_U.svg', alt: 'radox_kod_U connection code' },
        { image: '/assets/connection-codes/radox_kod_VKL.svg', alt: 'radox_kod_VKL connection code' },
        { image: '/assets/connection-codes/radox_kod_VKP.svg', alt: 'radox_kod_VKP connection code' }
      ],
      selectedTab: 2,
      detailsOpen: false,
      showDescription: false,
      showRewiew: false,
      showForDownloads: false,
      showAccessories: true,
      showProducent: false,
      productMark: null,
      showConnectionCode: false
    }
  },
  methods: {
    async productManufacturer (categories) {
      let productCatIds = categories.map(el => el.category_id)
      let searchQuery = new SearchQuery()
      let productMarkNoDesc = this.getCategories.find(el => el.parent_id === 10 && productCatIds.includes(el.id))
      const response = await quickSearchByQuery({
        entityType: 'category',
        query: productMarkNoDesc ? searchQuery.applyFilter({ key: 'id', value: { 'eq': productMarkNoDesc.id } }) : '',
        sort: '',
        size: 1,
        start: 0,
        includeFields: [...config.entities.category.includeFields, ...['description']],
        excludeFields: config.entities.optimize ? config.entities.category.excludeFields : null
      })
      this.productMark = response.items[0]
    },
    showDetails (event) {
      this.detailsOpen = true
      event.target.classList.add('hidden')
    },
    updateSelected (tab) {
      this.selectedTab = tab
    },
    updateChildSelected (tab) {
      this.selectedChildTab = tab
    },
    toogleAccessories () {
      if (!this.showAccessories) {
        this.showAccessories = true
      } else {
        this.showAccessories = false
      }
    },
    toogleConnectionCode () {
      if (!this.showConnectionCode) {
        this.showConnectionCode = true
      } else {
        this.showConnectionCode = false
      }
    },
    toogleDescription () {
      if (!this.showDescription) {
        this.showDescription = true
      } else {
        this.showDescription = false
      }
    },
    toogleProducent () {
      if (!this.showProducent) {
        this.showProducent = true
      } else {
        this.showProducent = false
      }
    },
    toogleRewiew () {
      if (!this.showRewiew) {
        this.showRewiew = true
      } else {
        this.showRewiew = false
      }
    },
    toogleForDownloads () {
      if (!this.showForDownloads) {
        this.showForDownloads = true
      } else {
        this.showForDownloads = false
      }
    }
  },
  computed: {
    imageResultResponse () {
      return this.$store.state.ui.imageResultResponse
    },
    isAccess () {
      return isInAccessorizes(this.getBreadcrumbsRoutes)
    },
    isConnCodes () {
      return !!this.prod.connection_code
    },
    isRelatedProducts () {
      return !!this.prod.product_links
    },
    product () {
      return this.$store.state.product
    },
    categoryDesc () {
      if (this.productMark && this.productMark !== null && this.productMark.description) {
        return this.productMark.description
      } else {
        return ''
      }
    },
    ...mapGetters({
      getOriginalProduct: 'product/getOriginalProduct',
      getCategories: 'category/getCategories',
      getBreadcrumbsRoutes: 'breadcrumbs/getBreadcrumbsRoutes'
    }),
    isOnline (value) {
      return onlineHelper.isOnline
    }
  }
}

</script>

<style scoped>
.tab-category-desc {
  color: #707070;
}
.tab-category-desc >>> h2 {
  font-size: 20px;
}
.tab-category-desc >>> p {
  font-size: 16px;
}
.tabDescription >>> h2 {
  font-size: 22px;
}
</style>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-alternative: color(alternative);
$color-orange: color(primary-orange);
$gray-text: color(matterhorn);
$light-border: color(gainsboro);
.mobile-datails {
  padding: 0 20px;
  @media (max-width: 991px) {
    padding: 0;
  }
}
.arrow {
  border: solid #191919;
  border-width: 0 1.5px 1.5px 0;
  display: inline-block;
  padding: 4px;
  right: 20px;
  bottom: 22px;
}
.bottom {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.top {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}
.custom-border {
  border-bottom: 1px solid #e0e0e0;
}
.p30 {
  padding: 30px;
}
.tabs {
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  {
    flex-basis: 100%;
    max-width: 100%;
  }
  @media (max-width: 991px) {
    padding: 0;
  }
  .tablist {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 10px;
    list-style: none;
    li {
      display: inline-block;
      color: #191919;
      padding: 5px 0px;
      font-size: 18px;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      &.current {
        font-weight: 500;
        color: $color-alternative;
       border-bottom: 2px solid #ff8100;
      }
    }
  }
}

@media (max-width: 767px) {
  .mobile-datails {
    background-color: white;
    margin-top: 0;
  }
  .accord-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 10px 15px 10px 10px;
    border-top: 1px solid #e0e0e0;
    &:first-child {
      border: none
    }
    &:last-child {
      border-bottom: 1px solid #e0e0e0;
    }
  }
}
</style>
