<template>
  <section class="row" v-if="product.related[type] && product.related[type].length > 0">
    <div class="col-xs-12 col-md-12 tab-header-box" v-show="!isNotification">
      <p v-for="(code, codeIndex) in getAccessTypes()" :key="code" class="tab-header mt10 pointer center-xs" :class="{ 'active' : activeTabIndex === codeIndex }" @click="changeActive(codeIndex)">
        {{ getLabelforValue('accessory_type', code) }}
      </p>
    </div>
    <div class="col-xs-12 p0">
      <div class="row m0 bottom-xs" v-for="(code, index) in getAccessTypes()" :key="index" v-show="activeTabIndex === index">
        <div class="col-md-3" v-for="(acc, accIndex) in accessoriesByType(code)" :key="acc.sku" v-if="tabToShow > accIndex || showAllAcc">
          <router-link
            :to="productLink(acc)"
            class="no-underline"
          >
            <div class="py20 flex column accessories relative" :class="{'align-end': isNotification}">
              <div class="content-mobile">
                <product-image
                  class="product-image"
                  :image="thumbnailObj(acc)"
                  :alt="acc.name | htmlDecode"
                />
                <div class="mobile-desc">
                  <p class="fs-medium-small">
                    {{ acc.name }}
                    <br>
                  </p>
                  <p class="my10 weight-700 fs16">
                    {{ acc.final_price | price }}
                  </p>
                  <div v-if="isNotification" class="bg-cl-primary-orange cl-white uppercase center-flex-text fs-medium-small m0 py5 pl20 brdr-radius-20">
                    {{ $t('Zobacz produkt') }}
                  </div>
                </div>
              </div>
              <div class="hidden-xs center-self">
                <div class="add-to-cart active">
                  <!-- <i class="icon-buy icon-add" /> -->
                  {{ $t('Zobacz produkt') }}
                </div>
              </div>
              <div v-if="!isNotification" class="actions flex around-xs pt15">
                <add-to-wishlist-btn
                  :product="acc"
                  class="fs16 btn-wish"
                />
                <div class="add-to-cart active">
                  {{ $t('Zobacz produkt') }}
                </div>
              </div>
            </div>
          </router-link>
        </div>
        <div class="show-more-acc cl-dark-blue mt10 mb30 h5 pointer center-xs uppercase" @click="toggleShowAllAcc()" v-show="!showAllAcc && accessoriesByType(code).length > 4">
          {{ $t('Pokaż wszystkie > ') }}
        </div>
        <div class="cl-dark-blue my10 ml20 center-xs uppercase fs-medium-small" @click="openAccTab()" v-show="isNotification && accessoriesByType(code).length > 1">
          {{ $t('Pokaż wszystkie > ') }}
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { MobileDetected } from 'theme/components/core/mobileDetected.ts'
import AddToWishlistBtn from 'theme/components/core/blocks/Wishlist/AddToWishlistBtn.vue'
import { getThumbnailPath as _thumbnailHelper } from '@vue-storefront/core/helpers'
import { mapGetters } from 'vuex'
import { prepareRelatedQuery } from '@vue-storefront/core/modules/catalog/queries/related'
import i18n from '@vue-storefront/i18n'
import store from '@vue-storefront/core/store'
import { formatProductLink } from '@vue-storefront/core/modules/url/helpers'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import config from 'config'
import getLabelMixin from 'theme/mixins/getLabelMixin.ts'
import ProductImage from 'theme/components/core/ProductImage'

export default {
  name: 'Related',
  mixins: [MobileDetected, getLabelMixin],
  props: {
    type: {
      type: String,
      required: true
    },
    heading: {
      type: String,
      required: false,
      default: i18n.t('Similar products')
    },
    isNotification: {
      type: [Boolean, String],
      default: false,
      required: false
    }
  },
  data () {
    return {
      isEmpty: false,
      accessories: [],
      activeTabIndex: 0,
      showAllAcc: false
    }
  },
  components: {
    AddToWishlistBtn,
    ProductImage
  },
  beforeMount () {
    this.$bus.$on('product-after-load', this.refreshList)
    if (store.state.config.usePriceTiers) {
      this.$bus.$on('user-after-loggedin', this.refreshList)
      this.$bus.$on('user-after-logout', this.refreshList)
    }

    this.refreshList()
  },
  beforeDestroy () {
    if (store.state.config.usePriceTiers) {
      this.$bus.$off('user-after-loggedin', this.refreshList)
      this.$bus.$off('user-after-logout', this.refreshList)
    }
  },
  destroyed () {
    this.$bus.$off('product-after-load', this.refreshList)
  },
  watch: {
    category (category) {
      if (category) {
        this.refreshList()
      }
    }
  },
  methods: {
    thumbnailObj (acc) {
      return {
        src: this.getThumbnail(acc.image, 150, 150),
        loading: this.getThumbnail(acc.image, 150, 150)
      }
    },
    openAccTab () {
      this.$store.dispatch('notification/removeNotification')
      if (document.getElementById('acc-scroll-to')) {
        let elementToView = document.getElementById('acc-scroll-to')
        elementToView.scrollIntoView({ behavior: 'smooth' })
      }
    },
    toggleShowAllAcc () {
      this.showAllAcc = !this.showAllAcc
    },
    changeActive (index) {
      this.activeTabIndex = index
    },
    getAccessTypes () {
      let accessories = []
      this.accessories.map(acc => !accessories.includes(acc.accessory_type) ? accessories.push(acc.accessory_type) : null)
      return accessories
    },
    accessoriesByType (type) {
      return this.accessories.filter(accessory => accessory.accessory_type === type)
    },
    productLink (product) {
      return formatProductLink(product, currentStoreView().storeCode)
    },
    getThumbnail (i) {
      return _thumbnailHelper(i, 150, 150)
    },
    refreshList () {
      let sku = []
      if (this.product.current.product_links) {
        this.product.current.product_links.map(el => {
          if (el.link_type === this.type) {
            sku.push(el.linked_product_sku)
          }
        })
      }
      let key = 'sku'
      let relatedProductsQuery = prepareRelatedQuery(key, sku)

      this.$store.dispatch('product/list', {
        query: relatedProductsQuery,
        size: sku.length,
        prefetchGroupProducts: false,
        updateState: false,
        includeFields: config.entities.productRelated.includeFields,
        excludeFields: config.entities.productRelated.excludeFields
      }).then((response) => {
        if (response) {
          this.accessories = response.items
          let isEmpty = response.items.length
          if (isEmpty > 0) {
            this.$store.dispatch('product/related', {
              key: this.type,
              items: response.items
            })
          } else {
            this.isEmpty = true
          }
          this.$forceUpdate()
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      category: 'category/getCurrentCategory'
    }),
    tabToShow () {
      return this.isMobile && this.isNotification ? 1 : 4
    },
    product () {
      return this.$store.state.product
    },
    productLinks () {
      return this.product.current.product_links
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';

$light-border: color(gainsboro);

.brdr-radius-20 {
  max-width: 160px;
  border-radius: 20px;
}
.acc-img {
  width: 150px;
  height: auto;
  @media (max-width: 767px) {
    width: 120px;
    height: auto;
  }
}
.show-more-acc {
  @media (max-width: 767px) {
    display: none;
  }
}
.content-mobile {
  @media (max-width: 767px) {
    display: flex;
    margin-bottom: 10px;
  }
  .mobile-desc {
    @media (max-width: 767px) {
      padding: 0 8px;
    }
  }
}
.align-end {
  align-items: end !important;
  padding: 5px !important;
}
.notification-position {
  right: 5px;
  position: absolute;
  bottom: 8px;
}
.accessories {
  @media (max-width: 767px) {
    border-bottom: 1px solid $light-border;
    flex-wrap: wrap;
    padding: 20px 10px;
  }
  .acc-image {
    width: 12%;
  }
  .price {
    width: 20%;
  }
  .to-cart {
    width: 20%;
  }
  .actions {
    width: 100%;
    padding-top: 15px;
    @media (min-width: 767px) {
      display: none;
    }
    @media (max-width: 767px) {
      padding: 0;
    }
    .btn-wish {
      width: 40%;
    }
    .btn-cart {
      width: 40px;
      min-width: 180px;
    }
  }
}
.add-to-cart {
  border: 1px solid #ff8100;
  background-color: #ffffff;
  color: #ff8100;
  display: block;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 100;
  border-radius: 20px;
  min-width: 110px;
  padding: 10px;
  white-space: nowrap;
  &.active {
    background-color: #ff8100;
    color: #fff;
  }
  @media (max-width: 767px) {
    font-size: 16px;
  }
}
.tab-header {
  color: #191919;
  display: inline;
  padding: 5px 25px;
  margin-right: 10px;
  font-size: 16px;
  text-transform: uppercase;
  @media (max-width: 767px) {
    display: flex;
    align-items: center;
  }
  &.active {
    font-weight: 500;
    border: 2px solid #191919;
  }
}
.left-border {
  border-left: 2px solid #ff8100;
}
.tab-header-box {
  margin-top: 20px;
   @media (max-width: 767px) {
      margin: 0;
      display: flex;
      justify-content: space-around;
   }
}
.product-image {
  @media (max-width: 767px) {
    max-width: 50%;
    padding-bottom: 200px !important;
  }
}
</style>
