<template>
  <button-full @click.native="isOnWishlist ? removeFromWishlist(product) : addToWishlist(product)"
               data-testid="addToCompare" class="add-compare-btn"
  >
    <i class="icon-schowek cl-primary-orange h4" />
    <p class="m0 pl10 h5 cl-orange">
      {{ $t('DO SCHOWKA') }}
    </p>
  </button-full>
</template>

<script>
import { formatProductMessages } from '@vue-storefront/core/filters/product-messages'
import focusClean from 'theme/components/theme/directives/focusClean'
import ButtonFull from 'theme/components/theme/ButtonFull.vue'
import { AddToCart } from '@vue-storefront/core/modules/cart/components/AddToCart'
import { IsOnWishlist } from 'src/modules/vsf-magento-wishlist/components/IsOnWishlist'
import { AddToWishlist } from 'src/modules/vsf-magento-wishlist/components/AddToWishlist'
import { RemoveFromWishlist } from 'src/modules/vsf-magento-wishlist/components/RemoveFromWishlist'

export default {
  mixins: [AddToCart, IsOnWishlist, AddToWishlist, RemoveFromWishlist],
  directives: { focusClean },
  components: { ButtonFull },
  methods: {

    onAfterRemovedVariant () {
      this.$forceUpdate()
    },
    notifyUser (notificationData) {
      this.$store.dispatch('notification/spawnNotification', notificationData, { root: true })
    }
  },
  computed: {
    isProductDisabled () {
      return this.disabled || formatProductMessages(this.product.errors) !== '' || this.isAddingToCart
    }
  },
  beforeMount () {
    this.$bus.$on('product-after-removevariant', this.onAfterRemovedVariant)
  },
  beforeDestroy () {
    this.$bus.$off('product-after-removevariant')
  }
}
</script>

<style lang="scss" scoped>
.add-compare-btn {
  padding: 5px;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 0;
  background-color: #fff;
  border: 1px solid #ff8100;
  color: #ff8100;
}
</style>
